import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics';

import '@/css/common.css'


Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-166767672-1',
  router
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
