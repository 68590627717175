<template>
    <div class="content" @wheel="scrollPage">
        <transition name="page">
            <div class="page_content">
                <div class="page" v-show="currentPage == 1">
                    <div class="pc">
                        <img class="bg" src="../assets/slide_page1_bg.webp" alt="">
                        <div class="img_box">
                            <img class="img" src="../assets/slide_page1.webp" alt="">
                            <div class="btn_box">
                                <a href="https://neakasa.com" class="link" @click="handleClick">
                                    <img src="../assets/slide_btn.png" alt="">
                                </a>
                                <div class="more_box" @click="more">
                                    <img src="../assets/Learn More.png" alt="">
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="mobile">
                        <div class="img_box_mobile">
                            <img class="img" src="../assets/slide_page1_mobile.webp" alt="">
                            <div class="btn_box">
                                <a href="https://neakasa.com" class="link" @click="handleClick">
                                    <img src="../assets/slide_btn.png" alt="">
                                </a>
                                <div class="more_box" @click="more">
                                    <img src="../assets/Learn More.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page page2" v-show="currentPage == 2">
                    <div class="pc">
                        <img class="bg" src="../assets/slide_page2_bg.webp" alt="">
                        <div class="img_box">
                            <img src="../assets/back.png" alt="" class="back" @click="back">
                            <img class="img" src="../assets/slide_page2.webp" alt="">
                            <div class="btn_box">
                                <a href="https://neakasa.com" class="link" @click="handleClick">
                                    <img src="../assets/slide_btn.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="mobile">
                        <div class="img_box_mobile">
                            <img src="../assets/back.png" alt="" class="back" @click="back">
                            <img class="img" src="../assets/slide_page2_mobile.webp" alt="">
                            <div class="btn_box">
                                <a href="https://neakasa.com" class="link" @click="handleClick">
                                    <img src="../assets/slide_btn.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </transition>
        
    </div>
</template>

<script>
export default {
    name: 'langding-page-down',
    data() {
        return {
            currentPage: 1
        }
    },
    created() {
       
    },
    methods: {
        handleClick() {
            this.$ga.event('button', 'click', 'button-click');
        },
        more() {
            this.currentPage = 2;
        },  
        back() {
            this.currentPage = 1;
        },  
        scrollPage(e) {
            // if(e.deltaY > 0 && this.currentPage == 1) { //下一页
            //     this.currentPage = 2;
            // }else if(e.deltaY < 0 && this.currentPage == 2){ //上一页
            //     this.currentPage = 1;
            // }
            // e.preventDefault();
        }
    }
}
</script>
<style scoped>
    .page_content,.page{
        width: 100%;
        height: 100%;
    }
    .img_box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    /* 进入动画 */
    .page-enter-active {
        animation: move 1s;
    }

    /* 离开动画 */
    .page-leave-active {
        animation: move 1s reverse;
    }

    .btn_box{
        position: absolute;
        left: 11%;
        bottom: 25%;
        width: 34%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .btn_box .link{
        width: 60%;
    }
    .more_box{
        width: 10vw;
        padding: 5px 0;
        border-bottom: 2px solid #2955cf;
        cursor: pointer;
    }
    .img_box_mobile{
        position: relative;
        height: 100%;
    }
    .img_box_mobile .img{
        height: 100%;
        object-fit: cover;
    }
    .mobile .btn_box{
        display: block;
        width: 50%;
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, 0);
    }
    .mobile .more_box {
        width: 50%;
        margin: 14% auto 0;
    }
    .back{
        position: absolute;
        top: 14%;
        left: 10%;
        width: 3%;
        cursor: pointer;
    }
    .page2 .btn_box{
        left: 10%;
        bottom: 11%;
    }
    .page2 .mobile .back{
        width: 12vw;
        height: 12vw;
        top: 8%;
    }
    .page2 .mobile .btn_box{
        left: 50%;
        bottom: 6%;
    }

    @keyframes move {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translate(0);
        }
    }
</style>