<template>
    <div>

    
        <div class="content" v-if="hostname.indexOf('neabot.com') != -1">
            <div class="pc">
                <img class="bg" src="../assets/page_bg_pc.webp" alt="">
                <div class="img_box">
                    <img class="img" src="../assets/page_bg_pc.webp" alt="">
                    <a href="https://neakasa.com" class="link" @click="handleClick">
                        <img src="../assets/btn.png" alt="" class="btn">
                    </a>
                </div>
            </div>
            <div class="mobile">
                <div class="img_box_mobile">
                    <img class="img" src="../assets/page_bg_mobile.webp" alt="">
                    <div class="btn_box">
                        <div class="bg"></div>
                        <a href="https://neakasa.com" class="link" @click="handleClick">
                            <img src="../assets/btn.png" alt="" class="btn">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="content" v-else>
            <div class="pc">
                <img class="bg" src="../assets/jp/page_bg_pc.webp" alt="">
                <div class="img_box">
                    <img class="img" src="../assets/jp/page_bg_pc.webp" alt="">
                    <a href="https://neakasa.jp" class="link" @click="handleClick">
                        <img src="../assets/jp/btn.webp" alt="" class="btn">
                    </a>
                </div>
            </div>
            <div class="mobile">
                <div class="img_box_mobile jp_img_box_mobile">
                    <img class="img" src="../assets/jp/page_bg_mobile.webp" alt="">
                    <div class="btn_box">
                        <a href="https://neakasa.jp" class="link" @click="handleClick">
                            <img src="../assets/jp/btn.webp" alt="" class="btn">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="icp">
            <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021024068号</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'langding-page',
    data() {
        return {
            hostname: ''
        }
    },
    created() {
        this.hostname = window.location.hostname;
    },
    methods: {
        handleClick() {
            this.$ga.event('button', 'click', 'button-click');
        }
    }
}
</script>
<style scoped>
    .img_box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .link{
        position: absolute;
        bottom: 9%;
        left: 9.5%;
        width: 21%;
    }
    .img_box_mobile{
        position: relative;
    }
    .btn_box{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 18%;
    }
    .btn_box .bg{
        background: #fff;
    }
    .btn_box .link{
        width: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .jp_img_box_mobile{
        position: absolute;
    }
    .jp_img_box_mobile .btn_box{
        position: absolute;
        bottom: 12%;
        height: auto;
    }
    .jp_img_box_mobile .link{
        position: initial;
        display: block;
        width: 50%;
        transform: translate(0, 0);
        margin: 0 auto;
    }
    .icp{
        position: relative;
        padding-bottom: 50px;
        text-align: center;
        color: #000;
    }
</style>