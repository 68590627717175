import Vue from 'vue'
import Router from 'vue-router'

import langdingPage from '@/view/landing-page'
// import langdingPageTimer from '@/view/landing-page-timer'
import langdingPageDown from '@/view/landing-page-down'

Vue.use(Router)
const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'langding-page',
			component: langdingPage,
		},
		{
			path: '/1',
			name: 'langding-page',
			component: langdingPage,
		},{
			path: '/2',
			name: 'langding-page-down',
			component: langdingPageDown,
		}
	]
})
router.beforeEach((to, from, next) => {
	const hostname = window.location.hostname;
	console.log(hostname)
	if(to.name && hostname.indexOf('neabot.com') != -1) {
		next();
	}else if(to.name == 'langding-page'){
		next();
	}else{
		const redirect_url = hostname.indexOf('neabot.com') != -1 ? 'https://neakasa.com' : 'https://neakasa.jp';
		window.location.replace(redirect_url + to.path);
	}
});
export default router;